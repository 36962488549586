
import { defineComponent, computed, ref, watch, onMounted } from "vue";
import { useStore } from "vuex";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useI18n } from "vue-i18n";
import TableSkeleton from "@/components/widgets/skeletons/TableSkeleton.vue";

export default defineComponent({
  name: "user-account",
  props: ["accountid"],
  components: { TableSkeleton },
  setup() {
    const store = useStore();
    const currentUser = store.getters.currentUser;
    const { d } = useI18n();
    const isDataReady = ref(false);

    const accountInfo = computed(() => {
      return store.getters.getSelectedApplicationUserProfile;
    });

    onMounted(() => {
      setCurrentPageTitle("User Profile");
      setCurrentPageBreadcrumbs("Account", ["User"]);
    });

    watch(accountInfo, () => {
      isDataReady.value = true;
    });

    return {
      currentUser,
      accountInfo,
      isDataReady,
      d,
    };
  },
});
