<template>
  <!--begin::Navbar-->
  <div class="card mb-5 mb-xl-10" v-if="isDataReady">
    <div class="card-body pt-9 pb-0">
      <!--begin::Details-->
      <div class="d-flex flex-wrap flex-sm-nowrap mb-3">
        <!--begin: Pic-->
        <div class="me-7 mb-4">
          <div
            class="
              symbol symbol-100px symbol-lg-160px symbol-fixed
              position-relative
            "
          >
            <img src="/media/avatars/snappy-avator.png" alt="image" />
            <div
              class="
                position-absolute
                translate-middle
                bottom-0
                start-100
                mb-6
                bg-success
                rounded-circle
                border border-4 border-white
                h-20px
                w-20px
              "
            ></div>
          </div>
        </div>
        <!--end::Pic-->

        <!--begin::Info-->
        <div class="flex-grow-1">
          <!--begin::Title-->
          <div
            class="
              d-flex
              justify-content-between
              align-items-start
              flex-wrap
              mb-2
            "
          >
            <!--begin::User-->
            <div class="d-flex flex-column">
              <!--begin::Name-->
              <div class="d-flex align-items-center mb-1">
                <a
                  href="#"
                  class="text-gray-800 text-hover-primary fs-2 fw-bolder me-1"
                  >{{ accountInfo.firstName }} {{ accountInfo.lastName }}
                </a>
              </div>
              <!--end::Name-->
              <!--begin::Info-->
              <div class="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                <a
                  v-if="accountInfo.jobTitle != ''"
                  href="#"
                  class="
                    d-flex
                    align-items-center
                    text-gray-400 text-hover-primary
                    me-5
                    mb-2
                  "
                  ><span class="svg-icon svg-icon-4 me-1">
                    <inline-svg
                      src="/media/icons/duotune/communication/com006.svg"
                    /> </span
                  >{{ accountInfo.jobTitle }}
                </a>
                <a
                  :href="'mailto:' + accountInfo.email"
                  class="
                    d-flex
                    align-items-center
                    text-gray-400 text-hover-primary
                    me-5
                    mb-2
                  "
                >
                  <span class="svg-icon svg-icon-4 me-1">
                    <inline-svg
                      src="/media/icons/duotune/communication/com011.svg"
                    />
                  </span>
                  {{ accountInfo.email }}
                </a>
              </div>
              <div>
                <span v-show="!accountInfo.deleted" class="badge badge-success"
                  >Active</span
                ><span v-show="accountInfo.deleted" class="badge badge-danger"
                  >Deactivated</span
                >
              </div>
              <!--end::Info-->
            </div>
            <!--end::User-->
          </div>
          <!--end::Title-->

          <!--begin::Stats-->
          <div class="d-flex flex-wrap flex-stack">
            <!--begin::Wrapper-->
            <div class="d-flex flex-column flex-grow-1 pe-8">
              <!--begin::Stats-->
              <div class="d-flex flex-wrap">
                <!--begin::Stat-->
                <div
                  class="
                    border border-gray-300 border-dashed
                    rounded
                    min-w-125px
                    py-3
                    px-4
                    me-6
                    mb-3
                  "
                >
                  <!--begin::Number-->
                  <div class="d-flex align-items-center">
                    <div class="fs-2 fw-bolder">
                      {{ d(accountInfo.accountCreatedLocal) }}
                    </div>
                  </div>
                  <!--end::Number-->

                  <!--begin::Label-->
                  <div class="fw-bold fs-6 text-gray-400">Account Created</div>
                  <!--end::Label-->
                </div>
                <!--end::Stat-->
              </div>
              <!--end::Stats-->
            </div>
            <!--end::Wrapper-->
          </div>
          <!--end::Stats-->
        </div>
        <!--end::Info-->
      </div>
      <!--end::Details-->

      <!--begin::Navs-->
      <div class="d-flex overflow-auto h-55px">
        <ul
          class="
            nav nav-stretch nav-line-tabs nav-line-tabs-2x
            border-transparent
            fs-5
            fw-bolder
            flex-nowrap
          "
        >
          <!--begin::Nav item-->
          <li class="nav-item">
            <router-link
              :to="{
                name: 'account-overview',
                params: {
                  accountid: accountid,
                },
              }"
              class="nav-link text-active-primary me-6"
              active-class="active"
            >
              Overview</router-link
            >
          </li>
          <!--end::Nav item-->
          <!--begin::Nav item-->
          <li class="nav-item">
            <router-link
              :to="{
                name: 'account-settings',
                params: {
                  accountid: accountid,
                },
              }"
              class="nav-link text-active-primary me-6"
              active-class="active"
            >
              Settings</router-link
            >
          </li>
          <!--end::Nav item-->
        </ul>
      </div>
      <!--begin::Navs-->
    </div>
  </div>
  <div v-else>
    <TableSkeleton></TableSkeleton>
  </div>
  <!--end::Navbar-->
  <router-view></router-view>
</template>

<script lang="ts">
import { defineComponent, computed, ref, watch, onMounted } from "vue";
import { useStore } from "vuex";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useI18n } from "vue-i18n";
import TableSkeleton from "@/components/widgets/skeletons/TableSkeleton.vue";

export default defineComponent({
  name: "user-account",
  props: ["accountid"],
  components: { TableSkeleton },
  setup() {
    const store = useStore();
    const currentUser = store.getters.currentUser;
    const { d } = useI18n();
    const isDataReady = ref(false);

    const accountInfo = computed(() => {
      return store.getters.getSelectedApplicationUserProfile;
    });

    onMounted(() => {
      setCurrentPageTitle("User Profile");
      setCurrentPageBreadcrumbs("Account", ["User"]);
    });

    watch(accountInfo, () => {
      isDataReady.value = true;
    });

    return {
      currentUser,
      accountInfo,
      isDataReady,
      d,
    };
  },
});
</script>
